<template>
  <div class="s_bf">
    <a-form ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item ref="type" label="消息类型" name="type">
        <a-select v-model:value="form.type" placeholder="请选择消息类型">
          <a-select-option :value="item.type" v-for='item in typeList' :key='item'>{{item.name}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item ref="name" label="触发项目" name="name">
        <a-input v-model:value="form.name" placeholder='请输入触发项目' />
      </a-form-item>
      <a-form-item ref="condition" label="触发时间说明" name="condition">
        <a-input v-model:value="form.condition" placeholder='请输入触发时间说明' />
      </a-form-item>
      <a-form-item ref="icon" label="Icon" name="icon" help='图片大小不超过2M，支持jpg/png格式'>
        <a-input v-model:value="form.icon" hidden />
        <a-upload v-model:fileList="adminfileList" name="file" accept='image/jpeg,image/png,image/jpg'
          list-type="picture-card" class="avatar-uploader" :action="baseUrl+'upload/alone'" :headers='header'
          @change="adminChange" @preview="handlePreview" :beforeUpload="beforeUpload">
          <div v-if='adminfileList.length == 0'>
            <plus-outlined />
            <div class="ant-upload-text">上传 Icon</div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item ref="sms_content" label="手机短信" name="sms_content">
        <a-textarea v-model:value="form.sms_content" showCount :maxlength="200" placeholder="请输入消息订阅内容" :rows="4" />
      </a-form-item>
      <a-form-item ref="message_content" label="用户消息中心模板" name="message_content">
        <a-textarea v-model:value="form.message_content" showCount :maxlength="200" placeholder="请输入站内信内容" :rows="4" />
      </a-form-item>
      <a-form-item ref="wx_content" label="微信模版" name="wx_content">
        <a-textarea v-model:value="form.wx_content" showCount :maxlength="200" placeholder="请输入站内信内容" :rows="4" />
      </a-form-item>
      <a-form-item ref="status" label="是否应用" name="status">
        <a-radio-group v-model:value="form.status">
          <a-radio :value="1">是</a-radio>
          <a-radio :value="0">否</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 8, offset: 4 }">
        <a-button type="primary" @click="onSubmit" :loading='loading'>
          保存
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
  import { PlusOutlined } from '@ant-design/icons-vue';
  import { ref, onMounted, reactive, toRefs, inject, watchEffect } from 'vue';
  import { message } from 'ant-design-vue';
  import { $iscode } from "@/utils/app";
  import { messPushAdd, messPushEdit } from "@/api/brand";

  import config from '@/config'

  import { setToken, getToken } from '@/libs/util'
  const baseUrl = (process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro)
  const header = { apiAuth: getToken() }
  import store from "@/store";
  // 初始化默认筛选项数值
  let defForm = {
    type: null,
    name: '',
    sms_content: '',
    wx_content: '',
    message_content: '',
    icon: '',
    condition: '',
    status: 1,
  }
  export default {
    name: 'msgPushForm',
    components: { PlusOutlined },
    props: {
      formData: {
        type: Object
      },
      typeList: {
        type: Array
      },
      type: {
        type: String
      }
    },
    setup(props, { emit }) {
      //pass
      const form = ref({ ...defForm });
      const state = reactive({
        info: store.state.user.info,
        adminfileList: [],
        loading: false,
        typeList: [],
        formData: {},
        labelCol: { span: 4 },
        wrapperCol: { span: 18 },
        baseUrl,
        header,
        rules: {
          type: [
            { required: true, message: '请选择消息类型' }
          ],
          name: [
            { required: true, message: '请输入触发项目' }
          ],
          condition: [
            { required: true, message: '请输入触发时间说明' }
          ],
          icon: [
            { required: true, message: '请上传Icon' }
          ],
          sms_content: [
            { required: true, message: '请输入手机短信模版' }
          ],
          message_content: [
            { required: true, message: '请输入用户消息中心模版' }
          ],
          wx_content: [
            { required: true, message: '请输入微信模版' }
          ],
        }
      });
      const ruleForm = ref()
      const _lodash = inject("_lodash");
      const adminChange = (info) => {
        console.log(info)
        if (info.file.status === 'done') {
          state.adminfileList = info.fileList.splice(info.fileList.length - 1, 1)
          form.value.icon = info.file.response.data.path
        }
        if (info.file.status === 'error') {
          message.error('图片上传失败！');
        }
        if (info.file.status === 'removed') {
          form.value.icon = ''
        }
      }
      const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }
      const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        state.previewImage = file.url || file.preview;
        state.previewVisible = true;
      }
      const handleCancel = () => {
        state.previewVisible = false;
      }
      const beforeUpload = (file, fileList) => {
        console.log(file, fileList)
        if (fileList.length > 1) {
          message.error('最多上传一张图');
          return false
        }
      }
      const onSubmit = () => {
        ruleForm.value.validate().then(async () => {
          const hide = message.loading('正在加载中...', 0);
          state.loading = true
          let res
          if (form.value.id) {
            res = await messPushEdit(form.value).then(res => res.data);
          } else {
            res = await messPushAdd(form.value).then(res => res.data);
          }
          if ($iscode(res)) {
            message.success(res.msg);
            emit('onsubmit')
          } else {
            message.error(res.msg);
          }
          setTimeout(hide);
          state.loading = false
        }).catch(error => {
          console.log('error', error);
        });
      }
      onMounted(() => {
        console.log(props)
        state.typeList = props.typeList
        if (props.type == 'edit') {
          form.value = props.formData
          state.adminfileList = [{ url: form.value.icon.indexOf(state.info.picdomain) == -1 ? state.info.picdomain + form.value.icon : form.value.icon }]
        }
      });
      return {
        form,
        ...toRefs(state),
        ruleForm,
        onSubmit,
        adminChange
      }
    }
  }
</script>
<style>
</style>